<template>
  <div class="pagination-container">
    <ul class="pagination-list">
      <li v-show="modelValue > page - 5 && modelValue < page + 5"
          v-for="page in pages"
          :key="page"
          :class="paginationClass(page)"
          @click.prevent="changePage(page)"><a href="#">{{ page }}</a></li>
    </ul>
    <div class="pagination-sorting" v-if="changePerPage">
      <span class="pagination-sorting-all">Всего: {{ total }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNav",
  props: {
    pages: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    changePerPage: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      itemsPerPage: this.perPage,
    };
  },
  methods: {
    changePage(goToPage) {
      if (goToPage === this.modelValue) return;
      this.$emit("update:modelValue", goToPage);
    },
    paginationClass(page) {
      return {
        "pagination-list__item": true,
        active: this.modelValue === page
      };
    },
    changeItemsPerPage(items) {
      if (items === this.perPage) return;
      this.$emit("update:modelValue", 1);
      this.$emit("update:perPage", items);
    },
  }
}
</script>

<style scoped>

</style>