<script setup async>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { AUTH_URL } from '@/common/config'
import {onMounted} from 'vue'

const router = useRouter()
const route = useRoute()
const store = useStore()

if (typeof route.query.logout != 'undefined') {
  window.location.href = AUTH_URL + '?redirect=/'
}

onMounted(async () => {
  await router.isReady()
  if (store.getters.isAuthenticated) {
    await router.push({name: "ListRegions"});
  }
})

</script>
<template>
  <div>

  </div>
</template>
