<script setup>

import MainFooter from '@/components/partial/MainFooter.vue'
import {Regions, Funds} from '@/common/api.service'
import {computed, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import moment from 'moment'
import Shared from "@/common/shared";
import Pagination from '@/components/partial/Pagination.vue'

const route = useRoute()
const router = useRouter()
const list = ref({
  agreements: []
})
const fl = ref({
  region: route.query.region?route.query.region:'',
  organizationTitle: route.query.organizationTitle?route.query.organizationTitle:'',
  district: route.query.district?route.query.district:'',
})
const s = ref({
  region: route.query.region?route.query.region:'',
  organizationTitle: route.query.organizationTitle?route.query.organizationTitle:'',
  district: route.query.district?route.query.district:'',
})
const fund = ref({})
const region = ref({})
const regionSelect = ref({})
const projectManagers = ref([])
const districts = ref([])
const regions = ref([])
const currentPage = ref(1)
const currentLimit = ref(20)
const pages = computed(() => {
  if (
      !list.value.totalAgreementsCount ||
      list.value.totalAgreementsCount <= currentLimit.value
  ) {
    return [];
  }
  return [
    ...Array(Math.ceil(list.value.totalAgreementsCount / currentLimit.value)).keys()
  ].map(e => e + 1);
})

watch(currentPage, async () => {
  fl.value.page = currentPage.value
  await fetchList(fl.value)
})

await Regions.list({})
    .then(({data}) => {
      regions.value = data
    }).catch(async () => {
      //await router.push({ name: "Error" });
    })

async function fetchList(fl) {
  let filter = {}
  if (fl.page) {
    filter.page = fl.page
  }
  if (fl.region && !fl.district) {
    await Regions.item(fl.region)
        .then(({data}) => {
          region.value = data
        }).catch(() => {
        })
    await Funds.item(region.value.fund.id)
        .then(({data}) => {
          fund.value = data
        }).catch(() => {
        })
    filter.regionId = fl.region
  } else if (!fl.region) {
    region.value = {}
    fund.value = {}
  }
  if (fl.district) {
    filter.districtId = fl.district
  }
  if (fl.organizationTitle) {
    filter.counterpartyTitleSearch = fl.organizationTitle
  }

  await Funds.agreementRegistry(filter)
      .then(({data}) => {
        list.value = data

      }).catch(async () => {
        await router.push({name: 'Error'})
      })
}

const regionList = computed(() => {
  let r = []
  regions.value.forEach(function (f) {
    if (!r.find(v => v.value === f.id)) {
      r.push({value: f.id, label: `${f.title}`})
    }
  })
  return r
})

await Regions.projectManagers()
    .then(({data}) => {
      data.forEach(function (f) {
        projectManagers.value.push({value: f.shortName, label: `${f.shortName}`})
      })
    }).catch(() => {
    })

await Regions.districts()
    .then(({data}) => {
      data.forEach(function (f) {
        districts.value.push({value: f.id, label: `${f.title}`})
      })
    }).catch(() => {
    })

async function appendFilter() {
  let f = JSON.stringify(s.value)
  fl.value = JSON.parse(f)
  await fetchList(fl.value)

  let query = {};

  for (const [key, value] of  Object.entries(fl.value)) {
    if (value){
      query[key]= value
    }
  }

  if (Object.keys(query).length > 0) {
    router.push({name: 'ListRegions', query: query})
    await router.push({name: 'ListAgreements', query: query})
  }else{
    await router.push({name: 'ListAgreements', query: {}})
  }

}

function clearFilter() {
  fl.value = {
    region: '', organizationTitle: '', district: '', page: 1
  }
  s.value = {
    region: '', organizationTitle: '', district: ''
  }
  router.push({name: 'ListAgreements', query: {}})
}

await fetchList(fl.value)


function gotoAddDogovor() {
  if (regionSelect.value) {
    router.push({name: 'AddAgreement', params: { id: regionSelect.value }})
  }
}
</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li><a href="/regions">Модуль регионов</a></li>
              <li v-if="fund.region">
                <router-link :to="{ name: 'CardRegions', params: { id: fund.region.id } }">{{ fund.region.title }}
                </router-link>
              </li>
              <li>Реестр договоров займа по самостоятельным программам
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper">
            <div class="content__title">
              <h1 class="title title-h1">Реестр договоров займа по самостоятельным программам
              </h1>
              <template v-if="region && region.id">
              <router-link :to="{ name: 'AddAgreement', params: {id: region.id} }"
                           :disabled="!region.id" class="button">
                Добавить договор
              </router-link>
              </template>
              <template v-else>
                <a class="button" @click="Shared.showModal('#open-select-region')"> Добавить договор</a>
              </template>
            </div>
            <form action="#" class="region-list__form">
              <div class="region-list__form-body">
                <Multiselect class="field field--select field--m-width-265 margin-0" v-model="s.region" mode="single"
                             :options="regionList" :show-no-results="false" :searchable="true"
                             placeholder="Название региона"
                             :disabled="(s.district !== '' && s.district !== null) || regionList.length === 1"
                             :no-options-text="'Укажите регион'"/>
                <input class="field field--m-width-265 margin-0" type="text" name="project-revenue"
                       v-model="s.organizationTitle"
                       @change="s.district !== ''? s.district='':''"
                       placeholder="Название организации">
                <Multiselect class="field field--select field--m-width-265 margin-0" v-model="s.district" mode="single"
                             :options="districts"
                             :disabled="s.organizationTitle !== ''"
                             @change="s.organizationTitle = ''"
                             :show-no-results="false" :searchable="true" placeholder="Округ"
                             :no-options-text="'Укажите округ'"/>
                <div class="region-list__counter hidden">Всего элементов: 4</div>
              </div>
              <div class="buttons-block">
                <button type="reset" class="button button--clear" @click="clearFilter">Очистить фильтры</button>
                <button type="button" class="button" @click="appendFilter">Поиск</button>
              </div>
            </form>
            <div class="table-responsive">
              <table class="table region-list">
                <thead>
                <tr>
                  <th>Название региона</th>
                  <th>Название организации</th>
                  <th>Дата договора займа</th>
                  <th>Сумма займа,млн руб</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in list.agreements" :key="item.id">
                  <td><a href="#" class="link link--big"></a>
                    <router-link :to="{ name: 'EditAgreement', params: {id: item.regionId, agreement: item.id } }"
                                 class="link link--big">
                      {{ item.regionTitle }}
                    </router-link>
                  </td>
                  <td>{{ item.organizationTitle }}
                  </td>
                  <td>{{ moment(item.agreementDate).format('DD.MM.YYYY') }}</td>
                  <td>{{ item.loanSum }}</td>
                </tr>
                </tbody>
              </table>
              <Pagination
                  :pages="pages"
                  v-model="currentPage"
                  :total="list.totalAgreementsCount"
                  :perPage="currentLimit"
                  @update:perPage="currentLimit = $event"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-overlay" id="open-select-region">
        <div class="popup">
          <div class="modal-title title-h3">Выбор региона для добавления договора займа по самостоятельным программам</div>
          <div class="modal-body">
              <Multiselect class="field field--select margin-0" v-model="regionSelect" mode="single"
                           :options="regionList" :show-no-results="false" :searchable="true" placeholder="Название региона"
                           :no-options-text="'Укажите регион'"/>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear" @click="Shared.closeModal('#open-select-region')">отменить
              </button>
              <button type="submit" class="button" @click="gotoAddDogovor">Продолжить</button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped></style>