<script setup>
import MainFooter from '@/components/partial/MainFooter.vue'
import {ref, computed, watch, onMounted} from 'vue'
import Shared from '@/common/shared'
import {useRouter, useRoute} from 'vue-router'
import {Dictionary, Regions, Capitalization, FileDocument} from '@/common/api.service'
import {useDropzone} from 'vue3-dropzone'
import {useToast} from 'vue-toastification'
import moment from 'moment'

const route = useRoute()
const router = useRouter()
const years = ref([])
const funds = ref([])
const selectedFund = ref(route.query.fund ? route.query.fund : '')
const loaded = ref(false)
const currentYear = new Date().getFullYear()
const selectedYear = ref(route.query.year ? route.query.year : '')
const selectedFundDetails = ref({id: '', title: ''})
const docFile = ref()
const uploadDocument = ref({})
const documents = ref({
  1: [], 2: [], 3: [], 4: [],
})
const budgetItem = ref([])
const budgetHistory = ref({})
const rowBudgetTemp = ref({})
const dragStarted = ref(false)
const toast = useToast()
const dbl = ref(0)

const selectedFundId = computed(() => {
  return selectedFund.value
})

const {getRootProps, getInputProps} = useDropzone({onDrop, accept: ['.pdf']})

function onDrop(acceptFiles) {
  docFile.value = acceptFiles[0]
  dragStarted.value = false
}

const dragEnter = () => {
  dragStarted.value = true
}
const dragEnd = () => {
  console.log('dragEnd')
}

async function onAddDocFile(e) {
  let files = e.target.files
  for (let i = 0; i < files.length; i++) {
    let file = files[i]
    docFile.value = file
  }
}

async function showNewDocument(quarter) {
  docFile.value = {}
  uploadDocument.value = {
    visibility: 0, quarter: quarter,
  }
  Shared.showModal('#add-document')
}

async function showUpdateDocument(quarter) {
  docFile.value = {}
  uploadDocument.value = {
    visibility: 0, quarter: quarter,
  }

  Shared.showModal('#edit-document')
}

async function uploadCapDocument() {
  if (docFile.value && docFile.value.type === 'application/pdf') {
    let formData = new FormData()
    formData.append('data', docFile.value)
    formData.append('name', docFile.value.name)
    formData.append('mimeType', docFile.value.type)
    await FileDocument.fileTemp(formData).then(({data}) => {
      uploadDocument.value.name = data.name
      uploadDocument.value.temporaryFileId = data.id
      Capitalization.createDocument(data.id, selectedFund.value, selectedYear.value, uploadDocument.value.quarter, uploadDocument.value)
          .then(async () => {
            await Capitalization.quarterDocuments(selectedFund.value, selectedYear.value, uploadDocument.value.quarter)
                .then(({data}) => {
                  dbl.value++
                  documents.value[uploadDocument.value.quarter] = data
                  Shared.closeModal('#add-document')
                  Shared.closeModal('#edit-document')
                  dbl.value++
                })
                .catch(() => {
                  dbl.value++
                })

          }).catch(() => {
        dbl.value++
      })
    }).catch(() => {
      dbl.value++
    })
  } else {
    dbl.value++
    toast.error('К загрузке доступен только файл в формате "pdf"', {
      timeout: 5000
    })
  }
}

async function downloadDoc(id) {
  await FileDocument.fileMeta(id)
      .then(async ({data}) => {
        await FileDocument.fileData(id)
            .then((response) => {
              console.log(response)
              let a = document.createElement('a')
              document.body.appendChild(a)
              a.style = 'display: none'
              let blob = new Blob([response.data], {type: 'octet/stream'})
              let url = window.URL.createObjectURL(blob)
              a.href = url
              a.download = data.name
              a.click()
            }).catch(() => {
            })
      }).catch(() => {
      })
}

const dataList = ref({
  capitalization: {
    rows: [
      {
        code: '',
        title: '',
        quarter1: 0,
        quarter2: 0,
        quarter3: 0,
        quarter4: 0,
        modifiedAt: null,
        modifiedBy: {
          id: '',
          email: '',
          firstName: '',
          lastName: '',
          middleName: '',
          cellular: '',
          phone: '',
          phoneExtention: '',
          shortName: ''
        }, level: 0,
        editable: true
      }
    ],
    total: {
      code: '', title: '', quarter1: 0, quarter2: 0, quarter3: 0, quarter4: 0, modifiedAt: null, modifiedBy: {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        middleName: '',
        cellular: '',
        phone: '',
        phoneExtention: '',
        shortName: ''
      }, level: 0, editable: true
    }
  }, budget: {
    years: [
      {
        rows: [
          {
            code: '',
            title: '',
            value: 0,
            createdAt: null,
            createdBy: {
              id: '',
              email: '',
              firstName: '',
              lastName: '',
              middleName: '',
              cellular: '',
              phone: '',
              phoneExtention: '',
              shortName: ''
            },
            history: [
              {
                code: '',
                title: '',
                value: 0,
                createdAt: null,
                createdBy: {
                  id: '',
                  email: '',
                  firstName: '',
                  lastName: '',
                  middleName: '',
                  cellular: '',
                  phone: '',
                  phoneExtention: '',
                  shortName: ''
                }
              }
            ]
          }
        ],
        total: {
          title: '',
          value: 0,
        },
        year: 0
      }
    ],
    requestedYear: 0
  }
})

const row = ref({
  year: null, code: '', quarter1: null, quarter2: null, quarter3: null, quarter4: null
})

const updateSelectedFundDetails = (newFund) => {
  const region = funds.value.find(item => item.fund.id === newFund)
  if (region) {
    selectedFundDetails.value = {regionId: region.id, id: region.fund.id, title: region.fund.title}
  }
}

async function filteredCapitalizationData() {
  loaded.value = false

  route.params.fund = selectedFund.value
  await router.push({name: 'CapitalizationTable', query: {fund: selectedFund.value, year: selectedYear.value}})

  if (selectedFund.value && selectedYear.value) {
    await Capitalization.FilteredCapitalizationData(selectedFund.value, selectedYear.value)
        .then(async ({data}) => {
          dataList.value = data
          for (let i = 1; i < 5; i++) {
            await Capitalization.quarterDocuments(selectedFund.value, selectedYear.value, i)
                .then(({data}) => {
                  documents.value[i] = data

                })
                .catch(() => {
                })
          }
          loaded.value = true
        })
        .catch(() => {
        })
  }
}

await Dictionary.fundCapitalizationYears()
    .then(({data}) => {
      years.value = data
    })
    .catch(() => {
    })

await Regions.list()
    .then(({data}) => {
      funds.value = data
      if (selectedFund.value && selectedFund.value.length > 0) {
        updateSelectedFundDetails(selectedFund.value)
      }
    })
    .catch(() => {
    })

const yearsList = computed(() => {
  let r = []
  years.value.forEach(function (year) {
    r.push({value: year.value, label: year.title})
  })
  return r
})

const fundsList = computed(() => {
  let r = []
  funds.value.forEach(function (item) {
    r.push({value: item.fund.id, label: item.fund.title})
  })
  return r
})

onMounted(() => {
  if (selectedFund.value.length > 0) {
    const defaultYear = years.value.find(year => year.value === currentYear)
    if (route.query.year) {
      selectedYear.value = route.query.year
    } else if (defaultYear) {
      selectedYear.value = defaultYear.value
    } else {
      selectedYear.value = ''
    }

    if (route.query.fund) {
      selectedFund.value = route.query.fund
    } else {
      selectedFund.value = ''
    }

  }
})

if (selectedFund.value && selectedYear.value) {
  await filteredCapitalizationData()
}

watch([selectedYear, selectedFund], async ([newYear, newFund]) => {
  newYear = Array.isArray(newYear) ? newYear : (newYear != null ? [newYear] : [])
  newFund = Array.isArray(newFund) ? newFund : (newFund != null ? [newFund] : [])

  if (newFund.length > 0 && newYear.length > 0) {
    await filteredCapitalizationData()
  }
})

watch(selectedFundId, (newFund) => {
  if (!newFund || newFund.length === 0) {
    selectedFundDetails.value = {id: '', title: ''}
  } else {
    updateSelectedFundDetails(newFund)
  }
})

function updateRowData(index) {
  const capitalizationRow = dataList.value.capitalization.rows[index]
  row.value = {
    year: dataList.value.budget.requestedYear,
    code: capitalizationRow.code,
    title: capitalizationRow.title,
    quarter1: capitalizationRow.quarter1,
    quarter2: capitalizationRow.quarter2,
    quarter3: capitalizationRow.quarter3,
    quarter4: capitalizationRow.quarter4
  }
}

/*function clearFilter () {
  selectedYear.value = ''
  selectedFund.value = ''

}*/

async function showEditCapitalization() {
  Shared.showModal('#edit-capitalization')
}

async function updateCapitalization() {
  await Capitalization.updateCapitalization(selectedFund.value, {
    year: row.value.year,
    code: row.value.code,
    quarter1: row.value.quarter1,
    quarter2: row.value.quarter2,
    quarter3: row.value.quarter3,
    quarter4: row.value.quarter4
  })
  Shared.closeModal('#edit-capitalization')
  await filteredCapitalizationData()
}

async function showEditBudget(row1, row2) {
  budgetItem.value = []
  let budget = JSON.stringify(row1)
  budgetItem.value.push(JSON.parse(budget))
  budget = JSON.stringify(row2)
  budgetItem.value.push(JSON.parse(budget))

  rowBudgetTemp.value.code = row1.code
  rowBudgetTemp.value.title = row1.title

  Shared.showModal('#edit-budget')
}

async function showHistoryBudget(row) {
  budgetHistory.value = row
  Shared.showModal('#budget-row-history')
}

async function updateBudget() {
  await Capitalization.updateBudget(selectedFund.value, {
    year: dataList.value.budget.years[0].year, code: budgetItem.value[0].code, value: parseInt(budgetItem.value[0].value),
  })
  await Capitalization.updateBudget(selectedFund.value, {
    year: dataList.value.budget.years[1].year, code: budgetItem.value[1].code, value: parseInt(budgetItem.value[1].value),
  })
  await filteredCapitalizationData()
  Shared.closeModal('#edit-budget')
}

</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li>
                <router-link :to="{ name: 'ListRegions' }">Модуль регионов</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'ListRegions' }">Список регионов</router-link>
              </li>
              <li v-if="selectedFundDetails && selectedFundDetails.id">
                <router-link
                    :to="{ name: 'CardRegions', params: { id: selectedFundDetails.regionId } }">
                  {{ selectedFundDetails.title }}
                </router-link>
              </li>
              <li>Капитализация РФРП</li>
              <li v-if="selectedFundDetails && !selectedFundDetails.id ">Выберите Фонд из списка</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="filters-container">
        <div class="container">
          <div class="filters">
            <div class="filters__body">
              <Multiselect class="field field--select field--m-width-265 margin-0" v-model="selectedYear"
                           mode="single" :options="yearsList" :show-no-results="false" :searchable="true"
                           placeholder="Год" :no-options-text="'Укажите год'"/>
              <Multiselect class="field field--select field--m-width-265 margin-0" v-model="selectedFund"
                           mode="single" :options="fundsList" :show-no-results="false" :searchable="true"
                           :disabled="fundsList.length === 1"
                           placeholder="Наименование РФРП" :no-options-text="'Укажите РФРП'"/>
            </div>
            <div class="filters__text">
              <a
                  class="link-black"
                  href="https://bod.frprf.ru/public/documents/shablon-dlya-zapolneniya-informacii-po-samostoyatelnym-zajjmam-rfrp"
                  target="_blank">Шаблон для заполнения информации по самостоятельным займам</a></div>
          </div>
        </div>
      </div>
      <div class="content" v-if="selectedFund && selectedYear && loaded">
        <div class="table-responsive table-responsive--padding table-responsive--bg-color-white">
          <table class="table capitalisation">
            <thead>
            <tr>
              <th>Показатель</th>
              <th colspan="5">Значение</th>
            </tr>
            </thead>
            <tbody>
            <tr class="title-row">
              <td>1. КАПИТАЛИЗАЦИЯ</td>
              <td colspan="5">По состоянию на {{ dataList.budget.year }} год</td>
            </tr>
            <tr>
              <td></td>
              <td>31.03</td>
              <td>30.06</td>
              <td>30.09</td>
              <td>31.12</td>
              <td></td>
            </tr>
            <tr v-for="(row, index) in dataList.capitalization.rows" :key="index" :class="{
                                'table-first-level': row.level === 1,
                                'table-second-level': row.level === 2,
                                'table-third-level': row.level === 3
                            }">
              <td class="title-td">{{ row.code }} {{ row.title }}</td>
              <td>{{ row.quarter1 }}</td>
              <td>{{ row.quarter2 }}</td>
              <td>{{ row.quarter3 }}</td>
              <td>{{ row.quarter4 }}</td>
              <td>
                <div class="table-button-block">
                  <button type="button" class="button-pen" v-if="row.editable"
                          @click="updateRowData(index); showEditCapitalization()"></button>
                </div>
              </td>
            </tr>
            <tr class="total-row">
              <td>{{ dataList.capitalization.total.code }} {{ dataList.capitalization.total.title }}</td>
              <td>{{ dataList.capitalization.total.quarter1 }}</td>
              <td>{{ dataList.capitalization.total.quarter2 }}</td>
              <td>{{ dataList.capitalization.total.quarter3 }}</td>
              <td>{{ dataList.capitalization.total.quarter4 }}</td>
              <td></td>
            </tr>
            <tr class="buttons-row">
              <td>
                Отчет о капитализации и бюджете (<a
                  href="https://bod.frprf.ru/public/documents/pismo-rfrp-po-kapitalizacii-i-byudzhetu"
                  download class="link link--color-main link--fs12">шаблон отчета</a>)
              </td>
              <td v-for="n in [1,2,3,4]" :key="n">
                <div class="buttons-column">
                  <button class="button table-btn-green" v-if="!documents[n].length" @click="showNewDocument(n)">
                    Загрузить
                  </button>
                  <button class="button table-btn-green" v-else @click="showUpdateDocument(n)">Обновить</button>
                  <!--                  <button type="button" class="link link&#45;&#45;color-main button-download"-->
                  <!--                          @click="downloadDoc(documents[n][0].fileId)" v-if="documents[n].length">-->
                  <!--                    Скачать-->
                  <!--                  </button>-->
                </div>
              </td>

              <td></td>
            </tr>
            <tr class="title-row">
              <td>2. БЮДЖЕТ на выдачу займов, в т.ч. план на текущий год</td>
              <td colspan="2" v-for="year in dataList.budget.years" :key="year">{{ year.year }} год</td>
            </tr>
            <tr v-for="(row, index) in dataList.budget.years[0].rows" :key="index">
              <td>{{ row.code }} {{ row.title }}</td>
              <td colspan="2">{{ row.value }}</td>
              <td colspan="2">{{ dataList.budget.years[1].rows[index].value }}</td>
              <td>
                <div class="table-button-block">
                  <button type="button" class="button-pen"
                          @click="showEditBudget(row, dataList.budget.years[1].rows[index])"></button>
                  <button type="button" class="button-history" @click="showHistoryBudget({
                  year1: dataList.budget.years[0].year,
                  year2: dataList.budget.years[1].year,
                  title: dataList.budget.years[0].rows[index].title,
                  code: dataList.budget.years[0].rows[index].code,
                  row1: dataList.budget.years[0].rows[index].history,
                  row2: dataList.budget.years[1].rows[index].history,
                  })"></button>
                </div>
              </td>
            </tr>
            <tr class="total-row" v-if="dataList.budget.years[0].rows.length > 1">
              <td>{{ dataList.budget.years[0].total.title }}</td>
              <td colspan="2">{{ dataList.budget.years[0].total.value }}</td>
              <td colspan="3">{{ dataList.budget.years[1].total.value }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-overlay" id="edit-capitalization">
        <div class="popup">
          <div class="modal-title title-h3">{{ row.code + ' ' + row.title }}</div>
          <div class="modal-body">
            <label for="row-year" class="title-field">Год
              <input class="field" type="text" v-model="row.year" placeholder="Введите год" disabled>
            </label>
            <label for="row-quarter1" class="title-field">Данные за 1 квартал
              <input class="field" type="text" v-model="row.quarter1"
                     placeholder="Введите данные за 1 квартал">
            </label>
            <label for="row-quarter2" class="title-field">Данные за 2 квартал
              <input class="field" type="text" v-model="row.quarter2"
                     placeholder="Введите данные за 2 квартал">
            </label>
            <label for="row-quarter3" class="title-field">Данные за 3 квартал
              <input class="field" type="text" v-model="row.quarter3"
                     placeholder="Введите данные за 3 квартал">
            </label>
            <label for="row-quarter4" class="title-field">Данные за 4 квартал
              <input class="field" type="text" v-model="row.quarter4"
                     placeholder="Введите данные за 4 квартал">
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear"
                      @click="Shared.closeModal('#edit-capitalization')">отменить
              </button>
              <button type="submit" class="button" @click="updateCapitalization">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-overlay" id="edit-budget">
        <div class="popup" v-if="budgetItem[0]">
          <div class="modal-title title-h3">
            {{ budgetItem[0].code }} {{ budgetItem[0].title }}
          </div>
          <div class="modal-body">
            <div v-for="(row, index) in budgetItem" :key="row">
              <label for="row-year" class="title-field">Год
                <input class="field" type="text" v-model="dataList.budget.years[index].year" placeholder="Введите год"
                       disabled>
              </label>
              <label for="row-value" class="title-field">Данные
                <input class="field" type="text" v-model="row.value" placeholder="Введите данные">
              </label>
            </div>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear"
                      @click="Shared.closeModal('#edit-budget')">отменить
              </button>
              <button type="submit" class="button" @click="updateBudget">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="budget-row-history">
        <div class="popup popup-oy-auto" v-if="budgetHistory.row1">
          <div class="modal-title title-h3">История изменения значений</div>
          <div class="modal-body">
            <div class="history-budget-outer">
              <h2 v-if="budgetHistory.code" style="margin-bottom: 8px">{{ budgetHistory.code }}
                {{ budgetHistory.title }}</h2>
              <h2 v-if="budgetHistory.year1" class="title-report">Год: <span
                  class="year">{{ budgetHistory.year1 }}</span></h2>

              <div class="history-budget-inner">
                <template v-if="budgetHistory.row1.length > 0">
                  <div
                      v-for="(item, index) in budgetHistory.row1.slice(0, 100)"
                      class="file-report-history"
                      :key="index">
                    <div class="line">
                      <div class="value">{{ item.value }}</div>
                      <div>{{ item.createdBy.lastName }} {{ item.createdBy.firstName }} {{ item.createdBy.middleName }},
                        {{ moment(item.createdAt).format('DD.MM.YYYY HH:mm') }}
                      </div>
                    </div>
                  </div>
                </template>
                <div v-else>
                  <div class="line">
                    <div class="value">Нет истории изменений</div>
                    <div></div>
                  </div>
                </div>
              </div>

              <h2 v-if="budgetHistory.year2">
                Год: <span class="year">{{ budgetHistory.year2 }}</span>
              </h2>
              <div class="history-budget-inner">
                <template v-if="budgetHistory.row2.length > 0">
                  <div v-for="(item, index) in budgetHistory.row2.slice(0, 100)" :key="index+' ' +item">
                    <div class="line">
                      <div class="value">{{ item.value }}</div>
                      <div>{{ item.createdBy.lastName }} {{ item.createdBy.firstName }} {{ item.createdBy.middleName }},
                        {{ moment(item.createdAt).format('DD.MM.YYYY HH:mm') }}
                      </div>
                    </div>
                  </div>
                </template>
                <div v-else>
                  <div class="line">
                    <div class="value">Нет истории изменений</div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button"
                      @click="Shared.closeModal('#budget-row-history')">закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="add-document" @dragenter="dragEnter" @dragleave="dragEnd">
        <div class="popup">
          <div class="modal-title title-h3">Добавление нового документа</div>
          <div class="modal-body">
            <label for="file-doc" class="title-field title-field--relative">Файл документа
              <div class="label-add-file transparent" v-bind="getRootProps()" :class="{
                'drag-started':dragStarted,
               }">
                <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
                <span class="field field--file" v-if="!docFile || !docFile.name">Выберите или перетащите
                                    сюда файл
                                    (максимум 25Мб)</span>
                <span class="field field--file" v-else>{{ docFile.name }}</span>
              </div>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear"
                      @click="Shared.closeModal('#add-document')">отменить
              </button>
              <button type="submit" class="button" @click="uploadCapDocument">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="edit-document" @dragenter="dragEnter" @dragleave="dragEnd">
        <div class="popup popup-oy-auto">
          <div class="modal-title title-h3">Обновление документа</div>
          <div class="modal-body">
            <label for="file-doc" class="title-field title-field--relative">Файл документа
              <div class="label-add-file transparent" v-bind="getRootProps()"
                   :class="{
                'drag-started':dragStarted,
               }"
              >
                <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
                <span class="field field--file" v-if="!docFile || !docFile.name">Выберите или перетащите
                                    сюда файл
                                    (максимум 25Мб)</span>
                <span class="field field--file" v-else>{{ docFile.name }}</span>
              </div>
            </label>
          </div>
          <div v-if="uploadDocument.quarter > 0 && documents[uploadDocument.quarter].length > 0"
               class="file-report-history">
            <div class="title-report">Год {{ documents[uploadDocument.quarter][0].year }}, квартал
              {{ documents[uploadDocument.quarter][0].quarter }}:
            </div>
            <div v-for="(item, index) in documents[uploadDocument.quarter]" :key="item">
              <div>
                {{ index + 1 }}. {{ item.uploader.position }} {{ item.uploader.lastName }} {{ item.uploader.firstName }}
                {{ item.uploader.middleName }}, {{ moment(item.uploadedAt).format('DD.MM.YYYY') }},
                <a href="javascript:void(0);" @click="downloadDoc(item.fileId)">скачать</a>
              </div>
            </div>

          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear"
                      @click="Shared.closeModal('#edit-document')">отменить
              </button>
              <button type="submit" class="button" :key="dbl" @click.once="uploadCapDocument">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped></style>
