import { createRouter, createWebHistory } from 'vue-router'

import HomePage from "@/components/HomePage.vue";
import ListRegions from "@/components/Regions/ListRegions.vue";
import CardRegions from "@/components/Regions/CardRegions.vue";
import ErrorPage from "@/components/ErrorPage.vue";
import OwnProgram from "@/components/Regions/OwnProgram.vue";
import ListAgreements from "@/components/Agreements/ListAgreements.vue";
import ListNpa from "@/components/Regulations/ListNpa.vue";
import CardNpa from "@/components/Regulations/CardNpa.vue";
import CardAgreement from "@/components/Agreements/CardAgreement.vue";
import CapitalizationTable from "@/components/Capitalization/CapitalizationTable.vue";

const router = createRouter({
  routes: [
    {
      path: "/",
      name: "HomePage",
      component: HomePage
    },
    {
      path: "/error",
      name: "Error",
      component: ErrorPage
    },
    {
      path: "/regions",
      name: "ListRegions",
      component: ListRegions
    },
    {
      path: "/region/:id",
      name: "CardRegions",
      component: CardRegions,
      meta: {
        title: "Регионы"
      }
    },
    {
      path: "/region/:id/own",
      name: "AddOwnProgram",
      component: OwnProgram,
      meta: {
        title: "Регионы"
      }
    },
    {
      path: "/region/:id/own/:own",
      name: "EditOwnProgram",
      component: OwnProgram,
      meta: {
        title: "Регионы"
      }
    },
    {
      path: "/agreements",
      name: "ListAgreements",
      component: ListAgreements
    },
    {
      path: "/agreements/:id/add",
      name: "AddAgreement",
      component: CardAgreement
    },
    {
      path: "/agreements/:id/:agreement",
      name: "EditAgreement",
      component: CardAgreement
    },
    {
      path: "/regulations",
      name: "ListNpaWithoutRegion",
      component: ListNpa
    },
    {
      path: "/regulations/:region?",
      name: "ListNpa",
      component: ListNpa
    },
    {
      path: '/regulations/:region/:title',
      name: 'CardNpa',
      component: CardNpa,
      props: (route) => ({
        selectedRegion: route.params.region,
        title: route.params.title
      })
    },
    {
      path: '/regulations/add-new-npa/:region?',
      name: 'AddCardNpa',
      component: CardNpa,
      props: (route) => ({ selectedRegion: route.query.region || "" })
    },
    {
      path: '/fund-assets',
      name: 'CapitalizationTable',
      component: CapitalizationTable
    }
    // {
    //   path: '/fund-assets',
    //   name: 'CapitalizationTableWithoutFund',
    //   component: CapitalizationTable
    // },
    // {
    //   path: '/fund-assets/:fund',
    //   name: 'CapitalizationTable',
    //   component: CapitalizationTable
    // }

  ],
  history: createWebHistory()
});
const DEFAULT_TITLE = 'ФРП';
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || DEFAULT_TITLE;
  next();
});
export default router;
