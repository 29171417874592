<script setup>

import MainFooter from '@/components/partial/MainFooter.vue'
import {Regions, Dictionary, Funds, FileDocument, Rgo, MySelf} from '@/common/api.service'
import {onMounted, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import Shared from '@/common/shared'
import moment from 'moment'
import ContactNPA from '@/components/Regions/ContactNPA.vue'
import ContactRGO from '@/components/Regions/ContactRGO.vue'
import DocumentRFRP from '@/components/Regions/DocumentRFRP.vue'
import DocumentRegion from '@/components/Regions/DocumentRegion.vue'
import DocumentRGO from '@/components/Regions/DocumentRGO.vue'
import CollabProgram from '@/components/Regions/CollabProgram.vue'
import MainRFRP from '@/components/Regions/MainRFRP.vue'
import MainRGO from '@/components/Regions/MainRGO.vue'
import FundCEO from '@/components/Regions/FundCEO.vue'
import {API_URL} from "@/common/config";

const route = useRoute()
const router = useRouter()

const item = ref({
  statistics: {},
  region: {
    id: null,
  },
  fund: {},
  responsibilities: [],
  fundContacts: [],
  capitalization: {},
  capitalizationStat: {},
  collabPrograms: [],
  ownPrograms: [],
  ownProgramsAgreements: [],
  fundDocuments: [],
  rgoDocuments: [],
  regionDocuments: [],
  rgo: {
    notCreated: true
  },
  agreementStatistics: {},
  npaStatistics: {},
  npaContacts: [],
  npaRegistry: []
})
const fund = ref({})
const rgo = ref({})
const ceo = ref({})
const ceoPhoto = ref('')
const ceoViewer = ref(null)
const permissions = ref([])
const projectManagers = ref([])
const regionalExperts = ref([])
const responsibilities = ref({
  projectManagerId: '', regionalExpertId: ''
})
const collab = ref({})
const docFile = ref()
const uploadDocument = ref({})
const hideRfrpInfo = ref(true)
const hideRgoInfo = ref(true)
const hideRgoDocs = ref(true)
const hideRegionDocs = ref(false)
const hideRfrpContacts = ref(true)
const hideRgoContacts = ref(true)
const hideSpikContacts = ref(true)
const contact = ref({})
const rgoAgreementStatuses = ref([])
const rgoAccreditations = ref([])
const rgoForm2021Statuses = ref([])
const rgoStatus = ref({})
const rgocomment = ref('')
const isRegionalFundEmployee = ref(false);

async function loadItem(id) {
  let fund = ''
  await Regions.item(id)
      .then(({data}) => {
        item.value.region = data
        fund = data.fund.id
      }).catch(() => {
      })
  await Funds.item(fund)
      .then(({data}) => {
        item.value.fund = data
        CEOPhoto()
      }).catch(() => {
      })
  await Regions.statistics(item.value.region.id)
      .then(({data}) => {
        item.value.statistics = data
      }).catch(() => {
      })
  await Regions.responsibilities(item.value.region.id)
      .then(({data}) => {
        item.value.responsibilities = data
      }).catch(() => {
      })
  await Funds.contacts(fund)
      .then(({data}) => {
        item.value.fundContacts = data
      }).catch(() => {
      })
  await Funds.capitalization(fund)
      .then(({data}) => {
        item.value.capitalization = data
      }).catch(() => {
      })
  await Funds.capitalizationStatistics(fund)
      .then(({data}) => {
        item.value.capitalizationStat = data
      }).catch(() => {
      })
  await Funds.collabPrograms(fund)
      .then(({data}) => {
        item.value.collabPrograms = data
      }).catch(() => {
      })
  await Funds.ownPrograms(fund)
      .then(({data}) => {
        item.value.ownPrograms = data
      }).catch(() => {
      })
  await Funds.ownProgramsAgreements(fund)
      .then(({data}) => {
        item.value.ownProgramsAgreements = data
      }).catch(() => {
      })
  await Funds.documents(fund)
      .then(({data}) => {
        item.value.fundDocuments = data
      }).catch(() => {
      })
  await Rgo.item(item.value.region.id)
      .then(({data}) => {
        if (data.id) {
          item.value.rgo = data
          item.value.rgo.notCreated = false
        }
      }).catch(() => {
      })
  if (item.value.rgo && item.value.rgo.id) {
    await Rgo.documents(item.value.rgo.id)
        .then(({data}) => {
          item.value.rgoDocuments = data
        }).catch(() => {
        })
  }

  await Funds.agreementStatistics(fund)
      .then(({data}) => {
        item.value.agreementStatistics = data
      }).catch(() => {
      })
  await Regions.documents(item.value.region.id)
      .then(({data}) => {
        item.value.regionDocuments = data
      }).catch(() => {
      })
  await Regions.npaStatistics(item.value.region.id)
      .then(({data}) => {
        item.value.npaStatistics = data
      }).catch(() => {
      })
  await Regions.npaRegistry().then(({data}) => {
    item.value.npaRegistry = data.filter(i => i.regionId === item.value.region.id)
  }).catch(() => {
  })
  item.value.npaContacts = []
  item.value.npaRegistry.forEach(function (f) {
    Regions.npaContacts(f.id)
        .then(({data}) => {
          data.forEach(function (i) {
            i.regulationId = f.id
            item.value.npaContacts.push(i)
          })
        }).catch(() => {
    })
  })
  await Dictionary.rgoAccreditations()
      .then(({data}) => {
        rgoAccreditations.value = data
      }).catch(() => {
      })
  await Dictionary.rgoAgreementStatuses()
      .then(({data}) => {
        rgoAgreementStatuses.value = data
      }).catch(() => {
      })
  await Dictionary.rgoForm2021Statuses()
      .then(({data}) => {
        rgoForm2021Statuses.value = data
      }).catch(() => {
      })
}

await Regions.permissions()
    .then(async ({data}) => {
      permissions.value = data
      await loadItem(route.params.id)
    }).catch(async () => {
      await router.push({name: 'Error'})
    })

function permission(permission) {
  return permissions.value.find((p) => p === permission)
}

async function showMainRFRP() {
  fund.value = item.value.fund
  fund.value.modal = true
}

async function showMainRGO() {
  rgo.value = item.value.rgo
  rgo.value.modal = true
}

async function showCreateMainRGO() {
  rgo.value = {
    'counterpartyId': '',
    'siteUrl': '',
    'generalEmail': '',
    'generalPhone': '',
    'loanLimit': 0,
    'suppliedLoansCount': 0,
    'rgoGuaranteeAmounts': [
      {
        'key': new Date().getFullYear(), 'value': 0
      }
    ],
    'address': '',
    'ogrn': ''
  }
  rgo.value.modal = true
}

async function showResponsibilities() {
  responsibilities.value.projectManagerId = item.value.responsibilities.projectManager.id
  responsibilities.value.regionalExpertId = item.value.responsibilities.regionalExpert.id

  await Regions.projectManagers()
      .then(({data}) => {
        projectManagers.value = data
      }).catch(() => {
      })
  await Regions.regionalExperts()
      .then(({data}) => {
        regionalExperts.value = data
      }).catch(() => {
      })

  Shared.showModal('#responsible-person')
}

async function saveResponsibilities() {
  await Regions.updateResponsibilities(item.value.region.id, responsibilities.value)
  await Regions.responsibilities(item.value.region.id)
      .then(({data}) => {
        item.value.responsibilities = data
      }).catch(() => {
      })
  Shared.closeModal('#responsible-person')
}

async function showAddCollabPrograms() {
  collab.value = {
    modal: true,
  }
}

async function showEditCollabPrograms(cl) {
  collab.value = {
    id: cl.id,
    modal: true,
    type: cl.collabProgramTypesType,
    startDate: cl.startDate,
    finishedAt: cl.finishedAt,
    rfrpSiteUrl: cl.rfrpSiteUrl,
    gispSiteUrl: cl.gispSiteUrl,
  }
}

async function showAddContact() {
  contact.value = {
    rgoModal: true,
  }
}

async function showEditContact(c) {
  contact.value = c
  contact.value.rgoModal = true
}

async function showNPAAddContact() {
  await Regions.npaRegistry().then(({data}) => {
    item.value.npaRegistry = data.filter(i => i.regionId === item.value.region.id)
    contact.value = {
      npaModal: true,
    }
  }).catch(() => {
  })
}

async function showNPAEditContact(c) {
  contact.value = c
  contact.value.npaModal = true
}

async function showRFRPDocument() {
  uploadDocument.value = {
    visibility: 0,
    rfrpModal: true,
  }
}

async function showEditRFRPDocument(doc) {
  uploadDocument.value = {
    id: doc.id,
    rfrpModal: true,
    typeName: doc.typeName, number: doc.number, name: doc.name, date: doc.date, visibility: doc.isPublic ? 1 : 0
  }
}

async function showDelRFRPDocument(doc) {
  uploadDocument.value = doc
  uploadDocument.value.rfrpModal = true
  uploadDocument.value.toDel = true
}

async function showRegionDocument() {
  uploadDocument.value = {
    visibility: 0,
    regionModal: true,
  }
}

async function showEditRegionDocument(doc) {
  docFile.value = {}
  uploadDocument.value = {
    id: doc.id,
    regionModal: true,
    typeName: doc.typeName, number: doc.number, name: doc.name, date: doc.date, visibility: doc.isPublic ? 1 : 0
  }
}

async function showDelRegionDocument(doc) {
  uploadDocument.value = doc
  uploadDocument.value.regionModal = true
  uploadDocument.value.toDel = true
}

async function showRGODocument() {
  uploadDocument.value = {
    visibility: 0,
    rgoModal: true,
  }
}

async function showEditRGODocument(doc) {
  docFile.value = {}
  uploadDocument.value = {
    id: doc.id,
    rgoModal: true,
    typeName: doc.typeName, number: doc.number, name: doc.name, date: doc.date, visibility: doc.isPublic ? 1 : 0
  }
}

async function showDelRGODocument(doc) {
  uploadDocument.value = doc
  uploadDocument.value.rgoModal = true
  uploadDocument.value.toDel = true
}

async function showEditRGOStatus() {
  rgoStatus.value = {
    accreditationStatus: item.value.rgo.signingState.accreditation,
    agreementStatus: item.value.rgo.signingState.signingStatus,
    signingDate: item.value.rgo.signingState.signDate,
    form2021Status: item.value.rgo.signingState.form2021Status,
  }

  Shared.showModal('#edit-status')
}

async function saveEditRGOStatus() {
  await Rgo.updateSigningState(item.value.region.id, item.value.rgo.id, rgoStatus.value)
  await Rgo.item(item.value.region.id)
      .then(({data}) => {
        item.value.rgo = data
        item.value.rgo.notCreated = false
      }).catch(() => {
      })
  Shared.closeModal('#edit-status')
}

async function saveEditRGOCommnet() {
  rgoStatus.value = {
    accreditationStatus: item.value.rgo.signingState.accreditation,
    agreementStatus: item.value.rgo.signingState.signingStatus,
    signingDate: item.value.rgo.signingState.signDate,
    form2021Status: item.value.rgo.signingState.form2021Status,
    comment: rgocomment.value
  }

  rgocomment.value = ''

  await Rgo.updateSigningState(item.value.region.id, item.value.rgo.id, rgoStatus.value)
  await Rgo.item(item.value.region.id)
      .then(({data}) => {
        item.value.rgo = data
        item.value.rgo.notCreated = false
      }).catch(() => {
      })
}


async function showEditCEO() {
  if (item.value.fund.ceo) {
    ceo.value = {
      modal: true,
      isAdded: true,
      fullName: item.value.fund.ceo.fullName,
      hiringDate: item.value.fund.ceo.hiringDate,
      fileId: item.value.fund.ceo.fileId,
    }
  } else {
    ceo.value = {
      modal: true,
    }
  }
}

async function downloadDoc(id, name) {
  await FileDocument.fileData(id)
      .then((response) => {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        let url = window.URL.createObjectURL(response.data)
        a.href = url
        a.download = name
        a.click()
      }).catch(() => {
      })
}

function blobToData(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

function CEOPhoto() {
  if (item.value.fund.ceo && item.value.fund.ceo.fileId) {
    FileDocument.fileDataBlob(item.value.fund.ceo.fileId)
        .then((response) => {

          blobToData(response.data).then((res) => {
            ceoPhoto.value = res
          })
        }).catch(() => {
    })
  }
}

function CEOViewPhoto() {
  const viewer = ceoViewer.value.$viewer
  viewer.show()
}

const goToRegulations = () => {
  const region = item.value.region.id
  router.push({name: 'ListNpa', params: {region}})
}
const goToCapitalization = () => {
  const fund = item.value.fund.id
  router.push({name: 'CapitalizationTable', query: {fund: fund}})
}

onMounted(async () => {
  await MySelf.card().then(async ({data}) => {
    isRegionalFundEmployee.value = data.isRegionalFundEmployee;
  });
});


</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li>
                <router-link :to="{ name: 'ListRegions' }">Модуль регионов
                </router-link>
              </li>
              <!--<li v-show="!isRegionalFundEmployee">
                <router-link :to="{ name: 'ListRegions' }">Список регионов
                </router-link>
              </li>-->
              <li>{{ item.region.title }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper">
            <div class="card-row">
              <div class="card-item">
                <div class="card-item__title">
                  <h2 class="title-h2">Статус рассмотрения заявок в ФРП <br>
                    <span>в том числе по совместным программам</span>
                  </h2>
                </div>
                <div class="table-container">
                  <table class="table table--table-mini">
                    <thead>
                    <tr>
                      <th>Текущий статус</th>
                      <th>Кол-во, шт.</th>
                      <th>Сумма займа, млн руб.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Профинансировано</td>
                      <td>{{ item.statistics.claimsVerificationStatus.completed.quantity }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.claimsVerificationStatus.completed.sum / 1000, 2) }}
                        млн
                      </td>
                    </tr>
                    <tr>
                      <td>В работе ФРП</td>
                      <td>{{ item.statistics.claimsVerificationStatus.inProgress.quantity }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.claimsVerificationStatus.inProgress.sum / 1000, 2) }}
                        млн
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-item">
                <div class="card-item__title">
                  <h2 class="title-h2">Ключевые показатели действующих и реализованных проектов<br>
                    <span>за {{ item.statistics.keyIndicatorsStatus.startYear }} - {{ item.statistics.keyIndicatorsStatus.endYear }} г.</span>
                  </h2>
                </div>
                <div class="table-container">
                  <table class="table table--table-mini">
                    <thead>
                    <tr>
                      <th>Выручка, млрд руб.</th>
                      <th>Налоги, млрд руб.</th>
                      <th>Инвестиции, млрд руб.</th>
                      <th>ВПРМ, шт.</th>
                      <th>РИДы, шт.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>{{ $filters.numberFormat(item.statistics.keyIndicatorsStatus.revenue, 2) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.keyIndicatorsStatus.taxes, 2) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.keyIndicatorsStatus.investments, 2) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.keyIndicatorsStatus.vprm, 0) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.keyIndicatorsStatus.rids, 0) }}</td>
                    </tr>
                    <!--<tr>
                      <td class="td-text-bold white-space-no">Всего проектов: <span>20</span></td>
                    </tr>-->
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-item">
                <div class="card-item__title">
                  <h2 class="title-h2">Совместные займы с РФРП
                  </h2>
                </div>
                <div class="table-container">
                  <table class="table table--table-mini">
                    <thead>
                    <tr>
                      <th>Текущий статус</th>
                      <th>Кол-во, шт.</th>
                      <th>Сумма займа ФРП, млн руб.</th>
                      <th>Сумма займа РФРП, млн руб.</th>
                      <th>Сумма займа итого, млн руб.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Профинансировано</td>
                      <td>{{ $filters.numberFormat(item.statistics.collaborationStatus.completed.quantity, 0) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.collaborationStatus.completed.frpLoanSum, 2) }}
                      </td>
                      <td>{{ $filters.numberFormat(item.statistics.collaborationStatus.completed.rfrpLoanSum, 2) }}
                      </td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.completed.loanSumTotal, 2)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>В работе ФРП</td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.inProgressFrp.quantity, 0)
                        }}
                      </td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.inProgressFrp.frpLoanSum, 2)
                        }}
                      </td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.inProgressFrp.rfrpLoanSum, 2)
                        }}
                      </td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.inProgressFrp.loanSumTotal, 2)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>В работе РФРП</td>
                      <td>{{ $filters.numberFormat(item.statistics.collaborationStatus.inProgress.quantity, 0) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.collaborationStatus.inProgress.frpLoanSum, 2) }}
                      </td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.inProgress.rfrpLoanSum, 2)
                        }}
                      </td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.inProgress.loanSumTotal, 2)
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-item">
                <div class="card-item__title">
                  <h2 class="title-h2">Самостоятельные программы РФРП
                  </h2>
                </div>
                <div class="table-container">
                  <table class="table table--table-mini">
                    <thead>
                    <tr>
                      <th>Текущий статус</th>
                      <th>Кол-во, шт.</th>
                      <th>Сумма займа, млн руб.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Профинансировано всего</td>
                      <td>{{
                          $filters.numberFormat(item.statistics.ownProgramsStatus.completedTotally.quantity, 0)
                        }}
                      </td>
                      <td>{{ $filters.numberFormat(item.statistics.ownProgramsStatus.completedTotally.sum, 2) }}</td>
                    </tr>
                    <tr>
                      <td>в т.ч. профинансировано за {{ new Date().getFullYear() }} г.</td>
                      <td>{{
                          $filters.numberFormat(item.statistics.ownProgramsStatus.completedTotally.quantity, 0)
                        }}
                      </td>
                      <td>{{ $filters.numberFormat(item.statistics.ownProgramsStatus.completedTotally.sum, 2) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="content__title content__title--margin">
              <h1 class="title">РФРП</h1>
            </div>
            <div class="card-row">
              <div class="card-item card-item--entry-info">
                <div class="card-item__title">
                  <h3 class="title-h3 align-items">Основная информация
                    <button type="button" class="button-edit" @click="showMainRFRP"
                            v-if="permission('/funds:update')"></button>
                  </h3>
                </div>
                <div class="entry-info entry-info--region-card grid-bg">
                  <div class="entry-info__item">Официальное название<a href="#" class="link width-60percent">
                    {{ item.fund.legalTitle }}
                  </a></div>
                  <div class="entry-info__item">Субъект РФ<span>
                      {{ item.region.title }}</span>
                  </div>
                  <div class="entry-info__item">Федеральный округ<span>{{ item.region.district.title }}</span>
                  </div>
                  <div class="entry-info__item">Сайт<span class="color-active"><a :href="item.fund.siteUrl"
                                                                                  target="_blank">{{
                      item.fund.siteUrl
                    }}</a></span></div>
                  <div class="entry-info__item">Общий e-mail<span class="color-active"><a
                      :href="'mailto:'+ item.fund.generalEmail">{{
                      item.fund.generalEmail
                    }}</a></span>
                  </div>
                  <div class="entry-info__item">Общий телефон<span class="color-active">{{
                      item.fund.generalPhone
                    }}</span></div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">ОГРН<span>{{ item.fund.ogrn }}</span>
                  </div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">Организационно-правовая форма<span
                      class="color-active">{{ item.fund.legalFormTitle }}</span></div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">Юридический адрес<span
                      class="width-60percent">{{ item.fund.legalAddress }}</span></div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">Фактический адрес<span
                      class="width-60percent color-active">{{ item.fund.actualAddress }}</span></div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">Учредитель РФРП<span
                      class="color-active">{{ item.fund.frpFounder }}</span></div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">Реквизиты НПА о создании РФРП<span
                      class="color-active">{{ item.fund.npaRequisites }}</span></div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">Дата подписания соглашения<span
                      class="color-active"><template v-if="item.fund.agreementSigningDate">{{
                      moment(item.fund.agreementSigningDate).format('DD.MM.YYYY')
                    }}</template></span>
                  </div>
                  <div class="entry-info__item">
                    <button type="button" class="button-hide align-items">
                      <span class="button-hide__text " :class="{
                        'button-hide__text--default': hideRfrpInfo,
                        'button-hide__text--active': !hideRfrpInfo
                      }" @click="hideRfrpInfo = false">Подробнее</span>
                      <span class="button-hide__text " :class="{
                        'button-hide__text--default': !hideRfrpInfo,
                        'button-hide__text--active': hideRfrpInfo
                      }" @click="hideRfrpInfo = true">Скрыть</span>
                      <span class="button-hide__icon"
                            @click="hideRfrpInfo = !hideRfrpInfo"
                            :class="{ show: !hideRfrpInfo }"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-item-wrapper">
                <div class="card-item card-item--entry-info">
                  <div class="card-item__title">
                    <h3 class="title-h3 align-items">Ответственные лица ФРП
                      <button v-if="permission('/regions/responsibilities:update')" type="button" class="button-edit"
                              @click="showResponsibilities"
                              data-modal="responsible-person"></button>
                    </h3>
                  </div>
                  <div class="entry-info entry-info--region-card">
                    <div class="entry-info__item">Менеджер от департамента проектов<span class="color-active">
                        {{ item.responsibilities.projectManager.shortName }}
                        {{ item.responsibilities.projectManager.email }}</span>
                    </div>
                    <div class="entry-info__item">Эксперт по региону<span class="color-active">
                        {{ item.responsibilities.regionalExpert.shortName }}
                        {{ item.responsibilities.regionalExpert.email }}
                      </span>
                    </div>

                  </div>
                </div>
                <div class="card-item card-item--entry-info paddingTopBottom-60">
                  <div class="card-item-box">
                    <div class="entry-info entry-info--region-card">
                      <div class="card-item__title">
                        <h3 class="title-h3 align-items">Директор РФРП
                          <button type="button" class="button-edit" @click="showEditCEO"
                                  v-if="permission('/regions/responsibilities:update')"></button>
                        </h3>
                      </div>
                      <div v-if="item.fund.ceo" class="entry-info__item">ФИО<span
                          class="color-active">{{ item.fund.ceo.fullName }}</span></div>
                      <div v-if="item.fund.ceo" class="entry-info__item">Лет на должности
                        <span class="color-active">{{ moment().diff(item.fund.ceo.hiringDate, 'years') }}</span></div>
                    </div>
                    <div class="card-item-box__img ceoPic" ref="ceoViewer"
                         v-viewer="{movalble: false, toolbar: false, navbar: false, title: false}">
                      <img :src="`${ceoPhoto}`" style="display: none" v-if="item.fund.ceo && item.fund.ceo.fileId"/>
                      <div @click="CEOViewPhoto" :style="{backgroundImage: `url(${ceoPhoto})`}"
                           v-if="item.fund.ceo && item.fund.ceo.fileId" ref="ceo-photo" class="img img--ceo"></div>
                      <div class="card-item-box__img-icon" v-else></div>
                    </div>
                  </div>
                </div>
                <div class="card-item card-item--entry-info">
                  <div class="card-item__title card-item__title--clickable"
                       @click="hideRfrpContacts = !hideRfrpContacts">
                    <h3 class="title-h3">Контактные лица РФРП</h3>
                    <span class="card-item__title-icon"
                          :class="{ show: !hideRfrpContacts }"
                    ></span>
                  </div>
                  <div class="table-container">
                    <table class="table table--table-mini table--table-mini-white"
                           :class="{ hidden: hideRfrpContacts }">
                      <thead>
                      <tr>
                        <th>ФИО</th>
                        <th>Должность</th>
                        <th>Телефон</th>
                        <th>E-mail</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.fundContacts" :key="i.id">
                        <td>{{ i.lastName }} {{ i.firstName }} {{ i.middleName }}</td>
                        <td>{{ i.position }}</td>
                        <td>{{ i.phone }}</td>
                        <td>{{ i.email }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="buttons-block buttons-block--right" :class="{ hidden: hideRfrpContacts }">
                    <a :href="'mailto:' + item.fundContacts.map(u => u.email).join(',')" class="button">отправить
                      письмо</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-row">
              <div class="card-item-wrapper">
                <div class="card-item card-item--bg-white">
                  <div class="card-item__title">
                    <h2 class="title-h2">Бюджет и капитализация РФРП</h2>
                  </div>
                  <div class="card-item__body">
                    <div class="card-item__body-item">Бюджет на 2024 г., млн руб.<span>

                        {{ $filters.numberFormat(item.capitalizationStat.budgetAmount, 2) }}</span>
                    </div>
                    <div class="card-item__body-item">Капитализация, млн руб.<span>
                        {{ $filters.numberFormat(item.capitalizationStat.capitalizationAmount, 2) }}</span>
                    </div>
                  </div>
                  <div class="buttons-block buttons-block--right">
                    <button type="button" class="button" @click="goToCapitalization">капитализация рфрп</button>
                  </div>
                </div>
                <div class="card-item">
                  <div class="card-item__title">
                    <h3 class="title-h2 align-items">Совместные программы РФРП
                      <button v-if="permission('/funds/collab-programs:update')" type="button" class="button-add"
                              @click="showAddCollabPrograms"></button>
                    </h3>
                  </div>
                  <div class="table-container">
                    <table class="table table--table-mini table--text-align-right">
                      <thead>
                      <tr>
                        <th>Название программы</th>
                        <th>Ссылка на навигатор ГИСП</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.collabPrograms" :key="i.id">
                        <td>
                          <a v-if="i.rfrpSiteUrl" :href="i.rfrpSiteUrl" target="_blank" class="link link--color-main">{{
                              i.title
                            }}</a>
                          <span v-else>{{ i.title }}</span>
                        </td>
                        <td>
                          <div class="table-button-block"><a v-if="i.gispSiteUrl" :href="i.gispSiteUrl"
                                                             class="button" target="_blank">Перейти</a>
                            <button v-if="permission('/funds/collab-programs:update')" type="button"
                                    class="button-edit" @click="showEditCollabPrograms(i)"></button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-item">
                  <div class="card-item__title">
                    <h3 class="title-h2 align-items">Документы РФРП
                      <button v-if="permission('/funds/documents:update')" type="button" class="button-add"
                              @click="showRFRPDocument"></button>
                    </h3>
                  </div>
                  <div class="table-container">
                    <table class="table table--table-mini">
                      <thead>
                      <tr>
                        <th>Тип документа</th>
                        <th>Номер документа</th>
                        <th>Дата документа</th>
                        <th>Публичный</th>
                        <th>Документ</th>
                        <th v-if="permission('/funds/documents:update')"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.fundDocuments" :key="i.id">
                        <td>{{ i.typeName }}</td>
                        <td>{{ i.number }}</td>
                        <td>{{ moment(i.date).format('DD.MM.YYYY') }}</td>
                        <td>{{ i.isPublic ? 'Да' : 'Нет' }}</td>
                        <td>
                          <button type="button" class="button-doc" @click="downloadDoc(i.fileId, i.name)"></button>
                        </td>
                        <td v-if="permission('/funds/documents:update')">
                          <div class="table-button-block">
                            <button type="button" class="button-edit" @click="showEditRFRPDocument(i)"></button>
                            <button type="button" class="button-del" @click="showDelRFRPDocument(i)"></button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-item-wrapper">
                <div class="card-item">
                  <div class="card-item__title">
                    <h3 class="title-h2 align-items">самостоятельные программы РФРП
                      <router-link v-if="permission('/funds/own-programs:update')"
                                   :to="{ name: 'AddOwnProgram', params: { id: item.region.id } }"
                                   class="button-add">
                      </router-link>
                    </h3>
                  </div>
                  <div class="table-container">
                    <table class="table table--table-mini">
                      <thead>
                      <tr>
                        <th>Название программы</th>
                        <th>Сумма, млн руб</th>
                        <th>Срок, лет</th>
                        <th>Ставка, %</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.ownPrograms" :key="i.id">
                        <td>
                          <router-link
                              :to="{ name: 'EditOwnProgram', params: { id: item.region.id, own: i.id } }"
                              class="link">{{ i.title }}
                          </router-link>
                        </td>
                        <td>{{ i.loanSumMin }} - {{ i.loanSumMax }}</td>
                        <td>{{ i.loanDuration }}</td>
                        <td>{{ i.rate }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-item card-item--bg-white">
                  <div class="card-item__title">
                    <h2 class="title-h2">договоры займа по самостоятельным программам</h2>
                  </div>
                  <div class="card-item__body">
                    <div class="card-item__body-item">Всего договоров<span>{{
                        item.agreementStatistics.totalCount
                      }}</span>
                    </div>
                    <div class="card-item__body-item">Сумма займов,
                      руб.<span>{{ $filters.numberFormat(item.agreementStatistics.totalLoanSum, 2) }}</span>
                    </div>
                  </div>
                  <div class="card-item__body">
                    <div class="card-item__body-item">Всего договоров в {{ new Date().getFullYear() }}
                      г.<span>{{ item.agreementStatistics.totalCountOfYear }}</span>
                    </div>
                    <div class="card-item__body-item">Сумма займов в {{ new Date().getFullYear() }} г.,
                      руб.<span>{{ $filters.numberFormat(item.agreementStatistics.totalLoanSumOfYear, 2) }}</span>
                    </div>
                  </div>
                  <div class="buttons-block buttons-block--right">
                    <router-link :to="{ name: 'ListAgreements', query: { region: item.region.id } }" class="button">
                      реестр договоров
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="content__title content__title--margin" v-if="permission('/rgo:read')">
              <h1 class="title">Региональная гарантийная организация</h1>
            </div>
            <div class="card-row" v-if="permission('/rgo:read')">
              <div class="card-item-wrapper">
                <div class="card-item card-item--entry-info">
                  <div class="card-item__title">
                    <h3 class="title-h3 align-items">Основная информация
                      <button type="button" class="button-edit" v-if="!item.rgo.notCreated && permission('/rgo:update')"
                              @click="showMainRGO"></button>
                      <button type="button" class="button-edit" v-else-if="permission('/rgo:update')"
                              @click="showCreateMainRGO"></button>
                    </h3>
                  </div>
                  <div class="entry-info entry-info--region-card grid-bg" v-if="!item.rgo.notCreated">
                    <div class="entry-info__item">Название РГО
                      <a
                          :href="(API_URL === 'https://test-regions.frprf.ru/api/')?'https://test-olk.frprf.ru/counterparty/'+ item.rgo.counterpartyId	+'/':'https://olk.frprf.ru/counterparty/'+ item.rgo.counterpartyId +'/'"
                          class="link width-60percent"
                          target="_blank">
                        {{ (item.rgo.shortName) ? item.rgo.shortName : item.rgo.longName }}
                      </a></div>
                    <div class="entry-info__item"
                         v-if="item.rgo.guaranteeAmounts">Размер
                      гарантийного капитала на {{ item.rgo.guaranteeAmounts[0].year }} г., млн
                      руб.<span class="color-active">{{ item.rgo.guaranteeAmounts[0].amount }}</span>
                    </div>
                    <div class="entry-info__item">Лимит на 1 заем, млн руб.<span class="color-active">{{
                        item.rgo.loanLimit
                      }}</span>
                    </div>
                    <div class="entry-info__item">Займы ФРП, обеспеченные РГО<span class="color-active">—</span></div>
                    <div class="entry-info__item" :class="{ hidden: hideRgoInfo }">Общий e-mail<span
                        class="color-active">info@frp61.ru</span></div>
                    <div class="entry-info__item" :class="{ hidden: hideRgoInfo }">Общий телефон<span
                        class="color-active">{{ item.rgo.generalPhone }}</span></div>
                    <div class="entry-info__item" :class="{ hidden: hideRgoInfo }">ОГРН<span>{{ item.rgo.ogrn }}</span>
                    </div>
                    <div class="entry-info__item" :class="{ hidden: hideRgoInfo }">Адрес<span class="color-active">{{
                        item.rgo.address
                      }}</span></div>
                    <div class="entry-info__item">
                      <button type="button" class="button-hide align-items">
                        <span class="button-hide__text " :class="{
                          'button-hide__text--default': hideRgoInfo,
                          'button-hide__text--active': !hideRgoInfo
                        }" @click="hideRgoInfo = false">Подробнее</span>
                        <span class="button-hide__text " :class="{
                          'button-hide__text--default': !hideRgoInfo,
                          'button-hide__text--active': hideRgoInfo
                        }" @click="hideRgoInfo = true">Скрыть</span>
                        <span class="button-hide__icon"
                              :class="{ show: !hideRgoInfo }"
                              @click="hideRgoInfo = !hideRgoInfo"
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-item" v-if="!item.rgo.notCreated">
                  <div class="card-item__title card-item__title">
                    <h2 class="title-h2 align-items">
                      <span class="title-h2--link --clickable" @click="hideRgoDocs = !hideRgoDocs">Документы РГО</span>
                      <button type="button" class="button-add" v-if="permission('/rgo/documents:update')"
                              @click="showRGODocument"></button>
                    </h2>
                    <span class="card-item__title-icon --clickable"
                          @click="hideRgoDocs = !hideRgoDocs"
                          :class="{ show: !hideRgoDocs }"
                    ></span>
                  </div>
                  <div class="table-container" :class="{ hidden: hideRgoDocs }">
                    <table class="table table--table-mini">
                      <thead>
                      <tr>
                        <th>Тип документа</th>
                        <th>Номер документа</th>
                        <th>Дата</th>
                        <th>Публичный</th>
                        <th>Документ</th>
                        <th v-if="permission('/rgo/documents:update')"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.rgoDocuments" :key="i.id">
                        <td>{{ i.typeName }}</td>
                        <td>{{ i.number }}</td>
                        <td>{{ moment(i.date).format('DD.MM.YYYY') }}</td>
                        <td>{{ i.isPublic ? 'Да' : 'Нет' }}</td>
                        <td>
                          <button type="button" class="button-doc" @click="downloadDoc(i.fileId, i.name)"></button>
                        </td>
                        <td v-if="permission('/rgo/documents:update')">
                          <div class="table-button-block">
                            <button type="button" class="button-edit" @click="showEditRGODocument(i)"></button>
                            <button type="button" class="button-del" @click="showDelRGODocument(i)"></button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-item-wrapper" v-if="!item.rgo.notCreated">
                <div class="card-item card-item--entry-info">
                  <div class="card-item__title">
                    <h3 class="title-h3 align-items">Статус подписания соглашения
                      <button type="button" class="button-edit" @click="showEditRGOStatus"
                              v-if="permission('/rgo:update')"></button>
                    </h3>
                  </div>
                  <div class="entry-info entry-info--region-card" v-if="item.rgo.signingState">
                    <div class="entry-info__item">Аккредитация в РГО
                      <span class="color-active" v-if="item.rgo.signingState.accreditation">
                        {{ rgoAccreditations.find(v => v.value === item.rgo.signingState.accreditation).title }}
                      </span>
                    </div>
                    <div class="entry-info__item">Статус соглашения
                      <span class="color-active" v-if="item.rgo.signingState.signingStatus">
                        {{ rgoAgreementStatuses.find(v => v.value === item.rgo.signingState.signingStatus).title }}
                      </span>
                    </div>
                    <div class="entry-info__item">Дата подписания<span class="color-active"
                                                                       v-if="!item.rgo.signingState.signingStatus">—</span>
                      <span class="color-active" v-if="item.rgo.signingState.signingStatus">{{
                          moment(item.rgo.signingState.signDate).format('DD.MM.YYYY')
                        }}</span>
                    </div>
                    <div class="entry-info__item">Заключение/перезаключение соглашения по типовой
                      форме 2021
                      <span class="color-active" v-if="item.rgo.signingState.form2021Status">
                        {{ rgoForm2021Statuses.find(v => v.value === item.rgo.signingState.form2021Status).title }}
                      </span>
                    </div>
                    <div class="entry-info__item">Комментарий
                      <div class="table-button-block">
                        <span class="color-active"
                              v-if="item.rgo.signingState.comments && item.rgo.signingState.comments[0]">
                            {{ item.rgo.signingState.comments[0].text }}</span>

                        <button type="button" class="button-edit" @click="Shared.showModal('#comment-status')"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-item card-item--entry-info">
                  <div class="card-item__title card-item__title">
                    <h2 class=" title-h2 align-items">
                      <span class="title-h2--link --clickable" @click="hideRgoContacts = !hideRgoContacts">Контактные лица</span>
                      <button type="button" class="button-add" @click="showAddContact"
                              v-if="permission('/rgo/contacts:update')"></button>
                    </h2>
                    <span class="card-item__title-icon  --clickable"
                          :class="{ show: !hideRgoContacts }"
                          @click="hideRgoContacts = !hideRgoContacts"
                    >
                    </span>
                  </div>
                  <div class="table-container">
                    <table class="table table--table-mini table--table-mini-white" :class="{ hidden: hideRgoContacts }">
                      <thead>
                      <tr>
                        <th>ФИО</th>
                        <th>Должность</th>
                        <th>Телефон</th>
                        <th>E-mail</th>
                        <th>Комментарий</th>
                        <th v-if="permission('/rgo/contacts:update')"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.rgo.contacts" :key="i.id">
                        <td>{{ i.fullName }}</td>
                        <td>{{ i.occupation }}</td>
                        <td>{{ i.phone }}</td>
                        <td>{{ i.email }}</td>
                        <td>{{ i.comment }}</td>
                        <td v-if="permission('/rgo/contacts:update')">
                          <a @click="showEditContact(i)" type="button" class="button-edit"></a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="content__title content__title--margin" v-if="permission('/regions:read')">
              <h1 class="title">Документы</h1>
            </div>
            <div class="card-row" v-if="permission('/regions:read')">
              <div class="card-item-wrapper">
                <div class="card-item">
                  <div class="card-item__title card-item__title">
                    <h2 class=" title-h2 align-items">
                      <span class="title-h2--link --clickable" @click="hideRegionDocs = !hideRegionDocs">Документы региона</span>
                      <button type="button" class="button-add" @click="showRegionDocument"
                              v-if="permission('/regions/documents:update')"></button>
                    </h2>
                    <span class="card-item__title-icon  --clickable"
                          :class="{ show: !hideRegionDocs }"
                          @click="hideRegionDocs = !hideRegionDocs"
                    ></span>
                  </div>
                  <div class="table-container" :class="{ hidden: hideRegionDocs }">
                    <table class="table table--table-mini">
                      <thead>
                      <tr>
                        <th>Тип документа</th>
                        <th>Номер документа</th>
                        <th>Дата</th>
                        <th>Публичный</th>
                        <th>Документ</th>
                        <th v-if="permission('/regions/documents:update')"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.regionDocuments" :key="i.id">
                        <td>{{ i.typeName }}</td>
                        <td>{{ i.number }}</td>
                        <td>{{ moment(i.date).format('DD.MM.YYYY') }}</td>
                        <td>{{ i.isPublic ? 'Да' : 'Нет' }}</td>
                        <td>
                          <button type="button" class="button-doc" @click="downloadDoc(i.fileId, i.name)"></button>
                        </td>
                        <td v-if="permission('/regions/documents:update')">
                          <div class="table-button-block">
                            <button type="button" class="button-edit" @click="showEditRegionDocument(i)"></button>
                            <button type="button" class="button-del" @click="showDelRegionDocument(i)"></button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="content__title content__title--margin" v-if="permission('/npa/contacts:read')">
              <h1 class="title">СПИК</h1>
            </div>
            <div class="card-row" v-if="permission('/npa/contacts:read')">
              <div class="card-item card-item--bg-white">
                <div class="card-item__title">
                  <h2 class="title-h2">НПА по спик</h2>
                </div>
                <div class="card-item__body">
                  <div class="card-item__body-item card-item__body-item-m-width100">Всего
                    документов<span>{{ item.npaStatistics.totalRegulations }}</span>
                  </div>
                </div>
                <div class="buttons-block buttons-block--right">
                  <button type="button" class="button" @click="goToRegulations">реестр нпа по спик</button>
                </div>
              </div>
              <div class="card-item card-item--entry-info">
                <div class="card-item__title card-item__title">
                  <h2 class=" title-h2 align-items">
                    <span class="title-h2--link --clickable" @click="hideSpikContacts = !hideSpikContacts">Контактные лица</span>
                    <button v-if="permission('/npa/contacts:update')" type="button" class="button-add"
                            @click="showNPAAddContact"></button>
                  </h2>
                  <span class="card-item__title-icon --clickable"
                        :class="{ show: !hideSpikContacts }"
                        @click="hideSpikContacts = !hideSpikContacts"
                  ></span>
                </div>
                <div class="table-container">
                  <table class="table table--table-mini table--table-mini-white"
                         :class="{ hidden: hideSpikContacts }"
                  >
                    <thead>
                    <tr>
                      <th>ФИО</th>
                      <th>Должность</th>
                      <th>Телефон</th>
                      <th>E-mail</th>
                      <th>Комментарий</th>
                      <th v-if="permission('/npa/contacts:update')"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="i in item.npaContacts" :key="i.id">
                      <td class="max-width-100 word-wrap">{{ i.fullName }}</td>
                      <td class="max-width-100 word-wrap">{{ i.occupation }}</td>
                      <td class="max-width-100 word-wrap">{{ i.phone }}</td>
                      <td class="max-width-100 word-wrap">{{ i.email }}</td>
                      <td class="max-width-100 word-wrap">{{ i.comment }}</td>
                      <td class="max-width-100 word-wrap" v-if="permission('/npa/contacts:update')">
                        <a @click="showNPAEditContact(i)" type="button" class="button-edit"></a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="responsible-person">
        <div class="popup">
          <div class="modal-title title-h3">Ответственные лица ФРП</div>
          <div class="modal-body">
            <label for="manager" class="title-field">Менеджер от департамента проектов
              <select v-model="responsibilities.projectManagerId" class="field field--select">
                <option v-for="i in projectManagers" :value="i.id" :key="i.id">{{ i.shortName }}</option>
              </select>
            </label>
            <label for="expert" class="title-field">Эксперт по региону
              <select v-model="responsibilities.regionalExpertId" class="field field--select">
                <option v-for="i in regionalExperts" :value="i.id" :key="i.id">{{ i.shortName }}</option>
              </select>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear" @click="Shared.closeModal('#responsible-person')">
                отменить
              </button>
              <button type="submit" class="button" @click="saveResponsibilities">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="edit-status">
        <div class="popup popup-670">
          <div class="modal-title title-h3">Редактирование статуса подписания соглашения</div>
          <div class="modal-body">
            <label for="accreditation-rgo" class="title-field">Аккредитация в РГО
              <select name="accreditation-rgo" v-model="rgoStatus.accreditationStatus" id=""
                      class="field field--select">
                <option :value="i.value" :key="i" v-for="i in rgoAccreditations">{{ i.title }}</option>
              </select>
            </label>
            <div class="interval-fields">
              <label for="status-rgo" class="title-field">Статус соглашения
                <select name="status-rgo" id="" v-model="rgoStatus.agreementStatus"
                        class="field field--select padding-right-25">
                  <option :value="i.value" :key="i" v-for="i in rgoAgreementStatuses">{{ i.title }}</option>
                </select>
              </label>
              <label for="date-rgo" class="title-field">Дата подписания
                <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                               v-model="rgoStatus.signingDate"/>
              </label>
            </div>
            <label for="select-rgo" class="title-field">Заключение/перезаключение соглашения по типовой
              форме 2021
              <select name="select-rgo" id="" v-model="rgoStatus.form2021Status" class="field field--select">
                <option :value="i.value" :key="i" v-for="i in rgoForm2021Statuses">{{ i.title }}</option>
              </select>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear" @click="Shared.closeModal('#edit-status')">отменить
              </button>
              <button type="submit" class="button" @click="saveEditRGOStatus">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="comment-status">
        <div class="popup popup-oy-auto">
          <div class="modal-title title-h3">Комментарий статуса подписания соглашения</div>
          <div class="modal-body" v-if="item.rgo.signingState && item.rgo.signingState.comments">
            <template v-for="i in item.rgo.signingState.comments" :key="i.createdAt">
              <div class="comment-item"
                   v-if="i.text"
              >
                <div class="comment-item__date title-h3">{{ moment(i.createdAt).format('DD.MM.YYYY') }}</div>
                <div class="comment-item__description">
                  <div class="comment-item__title">{{ i.text }}</div>
                  <div class="comment-item__text">{{ i.author.position }}</div>
                </div>
              </div>
            </template>
            <label for="comment1" class="title-field" v-if="permission('/rgo:update')">Комментарий
              <textarea class="field field--textarea" type="text"
                        placeholder="Введите комментарий"
                        v-model="rgocomment"
                        maxlength="2000"
              ></textarea>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear" @click="Shared.closeModal('#comment-status')">Закрыть
              </button>
              <button type="submit"
                      class="button"
                      v-if="permission('/rgo:update')"
                      :disabled="!rgocomment"
                      @click="saveEditRGOCommnet"
              >СОХРАНИТЬ
              </button>
            </div>
          </div>
        </div>
      </div>
      <DocumentRFRP :item="item" :upload-document="uploadDocument" v-if="uploadDocument.rfrpModal"></DocumentRFRP>
      <DocumentRegion :item="item" :upload-document="uploadDocument" v-if="uploadDocument.regionModal"></DocumentRegion>
      <DocumentRGO :item="item" :upload-document="uploadDocument" v-if="uploadDocument.rgoModal"></DocumentRGO>
      <CollabProgram :item="item" :collab="collab" v-if="collab.modal"></CollabProgram>
      <ContactRGO :item="item" :contact="contact" v-if="contact.rgoModal"></ContactRGO>
      <ContactNPA :item="item" :contact="contact" v-if="contact.npaModal"></ContactNPA>
      <MainRFRP :item="item" :fund="fund" v-if="fund.modal"></MainRFRP>
      <MainRGO :item="item" :rgo="rgo" v-if="rgo.modal"></MainRGO>
      <FundCEO :item="item" :ceo="ceo" :ceo-photo="CEOPhoto" v-if="ceo.modal"></FundCEO>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped></style>